body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

h1,
h2 {
    color: #993399;
}

p {
    color: #706468;
}

// change the theme in bootstrap
$theme-colors: (
    "primary": #993399,
    "secondary": #d7afd5,
);


@import '~bootstrap/scss/bootstrap.scss';
@import 'bootstrap/dist/css/bootstrap.min.css';
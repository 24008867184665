form .form-control:focus {
    border-color: #993399;
    box-shadow: none;
}

input[type='radio']:checked {
    background-color: #993399;
    box-shadow: none;
    border: none
}

input[type='checkbox']:checked {
    background-color: #993399;
    box-shadow: none;
    border: none
}

// react-bootrap accordion

.accordion-button {
    color: black !important;
    background-image: linear-gradient(to right, #fbeff8, white);
}

.accordion-button:hover {
    background-color: #e0c8e9 !important;
    outline: none !important;
}

.accordion-button:focus {
    box-shadow: none !important;
    color: #993399 !important;
}


// react-select

.custom_drop_down__control.custom_drop_down__control--is-focused {
    border: 1px solid #993399;
    background-color: #f8f6fc;
    box-shadow: none;
    outline: none;
}

.custom_drop_down__control.custom_drop_down__control:hover {
    border: 1px solid #993399
}

.custom_drop_down__option.custom_drop_down__option--is-selected {
    background-color: white !important;
    color: #993399 !important;
}

.custom_drop_down__option.custom_drop_down__option:hover {
    background-color: #f8f6fc !important;
}